import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { postForm } from "../../helpers/SendFormHelper";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    postForm(form.getAttribute("name"), this.state)
      // fetch("/", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   body: encode({
      //     "form-name": form.getAttribute("name"),
      //     ...this.state,
      //   }),
      // })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section" style={{ marginTop: "7rem" }}>
          <div className="container">
            <div className="columns">
              <div className="column is-8">
                <div className="content">
                  <h1>Contact</h1>
                  <form
                    name="contact"
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{" "}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={"name"}>
                        Your name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={"text"}
                          name={"name"}
                          onChange={this.handleChange}
                          id={"name"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={"phone"}>
                        Phone
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={"tel"}
                          name={"phone"}
                          onChange={this.handleChange}
                          id={"phone"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={"email"}>
                        Email
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={"email"}
                          name={"email"}
                          onChange={this.handleChange}
                          id={"email"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={"message"}>
                        Message
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={"message"}
                          onChange={this.handleChange}
                          id={"message"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button className="button is-link" type="submit">
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="column is-4">
                <div className="section">
                  <h3 className="title is-3">Don’t Hesitate To Reach Out</h3>
                  <p>
                    We understand that a home is one of the most important
                    financial decisions you’ll make. A home can have more value
                    than just an investment though; it’s where you raise your
                    family, become engulfed in a community, and never question
                    the feeling of home. We’re here for you. Please reach out to
                    us with any questions or concerns you may have. The advice
                    is always free!
                  </p>
                </div>
                <div className="section">
                  <h3 className="title is-3">We’re Always Available</h3>
                  <p>
                    Email, Call, Text, Chat, Message, or Drop in and say Hi. Any
                    way that works for you is best for us!
                  </p>
                  <p>
                    Email:{" "}
                    <a
                      href="mailto:Ronnie@rhomeloans.com"
                      className="has-text-link"
                    >
                      Ronnie@rhomeloans.com
                    </a>
                  </p>
                  <p>
                    Phone:{" "}
                    <a href="tel:8582645748" className="has-text-link">
                      (858) 264-5748
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
